.root {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-2);
  line-height: 1;

  border-radius: var(--round-corners);

  background-color: var(--palette-primary-100);
  color: var(--palette-primary-500);

  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);

  margin-right: var(--spacing-1);
}

.button {
  color: var(--palette-text-100);
}

.button span {
  display: block;
}
